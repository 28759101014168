// @ts-nocheck
//@ flow

import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RootContext } from '@/root/root.provider';

import { getExpandedText, getCollapsedText } from './banner.service';

import './banner.scss';
import { AlertBox, CrossIcon } from '@ticketmaster/global-design-system';

const BannerBlock = ({ t, config }: any) => {
  const rootContext = useContext(RootContext);
  const [isExpanded, setIsExpanded] = useState(true);

  if (!config) return null;

  if (config.ttl && typeof config.ttl === 'number') setTimeout(() => {
    rootContext.showBanner(null);
    config.onRequestClose?.();
  }, config.ttl);

  const closeBanner = () => {
    rootContext.showBanner(null);
    config.onRequestClose?.();
  };

  return (
    <div style={{ width: '100%' }}>
      <AlertBox
        title={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <span>{t(config.heading)}</span>
              {config.content && (
                <button
                  onClick={() => setIsExpanded((prev) => !prev)}
                  style={{ border: 'none', background: 'none', color: '#0000FF', cursor: 'pointer', fontSize: '14px' }}
                  aria-expanded={isExpanded}
                >
                  {isExpanded ? t(getExpandedText(config)) : t(getCollapsedText(config))}
                </button>
              )}
            </div>

            {!config.onRequestClose && // FIXME BLOX-3038: should stick to the right side of the title area
                <div style={{ maxWidth: '10%' }}>
                    <button
                        onClick={closeBanner}
                        style={{ border: 'none', background: 'none' }}
                        aria-label={t('Close')}
                    >
                        <CrossIcon/>
                    </button>
                </div>}
          </div>
        }
        variant={config.variant}
      >
        <div style={{ maxHeight: isExpanded ? '100px' : '0', overflow: 'hidden', opacity: isExpanded ? 1 : 0, transition: 'opacity 0.5s ease, max-height 0.5s ease' }}>
          {t(config.content)}
        </div>
      </AlertBox>
    </div>
  );
};

export default withTranslation('translation')(BannerBlock);
