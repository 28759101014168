// @ts-nocheck

import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { TextStyle } from '@ticketmaster/global-design-system';
import pSBC from 'shade-blend-color';

import { T } from '../../../../helpers/i18n';

import './event.scss';
import { getEventTitleColor, getFieldBackgroundColor, getTextFontColor } from '../../../../helpers/ticketColors/ticketColorsHelper';
import VoidedDateAndLocation from './dateAndLocation/voidedDateAndLocation';
import DateAndLocation from './dateAndLocation/dateAndLocation';
import { FIELDS_BG_COLOR_HEX } from '../fields/field/field.constants';
import { EventModel } from '../../../../models/eventModel';

type Props = {
  event: EventModel
  ticket: TicketModel
} & T;

const TicketEvent = memo(({ event, ticket, t }: Props) => {
  const ticketFontColor = getTextFontColor(ticket);
  const ticketPortalStyle = { color: ticketFontColor };
  const ticketBackgroundColor = getFieldBackgroundColor(ticket);
  const eventTitleColor = getEventTitleColor(ticket);

  if (ticket.voidedAt == null && ticket.exitPortal) {
    ticketPortalStyle.borderTop = '1px solid ' + pSBC(0.5, ticket.bgColorHex || FIELDS_BG_COLOR_HEX);
    ticketPortalStyle.width = '100%';
  }

  function ticketDateAndLocation() {
    if (ticket.voidedAt) {
      return <VoidedDateAndLocation/>;
    }
    return <DateAndLocation event={event}/>;
  }

  return (
    <section className="ticket-event">
      <div
        className={`ticket-portal ${ticket.exitPortal ? 'title' : ''}`}
        style={{ backgroundColor: ticket.exitPortal ? ticketBackgroundColor : '', display: 'flex' }}
      >
        <TextStyle.Rainier style={ticketPortalStyle}>{ticket.exitPortal || ''}</TextStyle.Rainier>
      </div>
      <div className="ticket-event-data">
        {!!event.title ? (
          <TextStyle.Blanc className="text-block" style={{ color: eventTitleColor }}>
            {event.title}
          </TextStyle.Blanc>
        ) : (
          <div/>
        )}
        {ticketDateAndLocation()}
      </div>
    </section>
  );
});

export default withTranslation('translation')(TicketEvent);
