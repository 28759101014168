// @ts-nocheck
import { T } from '@/helpers/i18n';
import React, { Component } from 'react';
import { RootContext } from '@/root/root.provider';
import { Button, ButtonWithLoading } from '@ticketmaster/aurora';
import { Trans, withTranslation } from 'react-i18next';
import AlertBanner from '../../banner/alertBanner';

import './manageTicketButton.scss';
import { EventModel } from '@/models/eventModel';
import { RedemptionAvailabilityModel } from '@/models/envelopeModel';

type Props = {
  activeToken: string;
  event: EventModel;
  redemptionAvailability: RedemptionAvailabilityModel;
  tmOrderUrl: string;
} & T;

type State = {
  showBanner: boolean
  isLoading: boolean
};

class ManageTicketButton extends Component<Props, State> {

  static contextType = RootContext;

  state = {
    showBanner: false,
    isLoading: false
  };

  checkDisabled(subSystemSource: string, redemptionDisabled: boolean | undefined) {
    return this.isEmpty(subSystemSource) || !!redemptionDisabled;
  }

  private isEmpty(subSystemSource: string) {
    return (subSystemSource == null || subSystemSource.length === 0);
  }

  render() {
    const { activeToken, event, redemptionAvailability, tmOrderUrl } = this.props;

    if (tmOrderUrl) {
      return (
        <div className="manage-tickets-button">
          <Button onClick={() => window.location.assign(tmOrderUrl)}>
            <Trans>{'WALLET.TICKETS.MANAGE'}</Trans>
          </Button>
        </div>
      );
    } else if (!this.state.showBanner) {
      return (
        <div className="manage-tickets-button" onClick={() => {
          if (this.checkDisabled(event?.subSystemSource, redemptionAvailability?.redemptionDisabled)) {
            this.setState({ showBanner: true });
          }
        }}>
          <ButtonWithLoading
            isLoading={this.state.isLoading}
            className={`${this.checkDisabled(event?.subSystemSource, redemptionAvailability?.redemptionDisabled) ? 'disabled' : 'enabled'}`}
            onClick={async () => {
              if (!this.state.isLoading) {
                this.setState({ isLoading: true });
                await this.context.addToAccount(activeToken);
                this.setState({ isLoading: false });
              }
            }}
          >
            <Trans>{'WALLET.TICKETS.MANAGE'}</Trans>
          </ButtonWithLoading>
        </div>
      );
    } else {
      return <AlertBanner onClose={() => this.setState({ showBanner: false })} redemptionAvailability={redemptionAvailability}/>;
    }
  }
}

export default withTranslation('translation')(ManageTicketButton);
