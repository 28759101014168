// @ts-nocheck

import React, { Component, memo } from 'react';
import { TextStyle } from '@ticketmaster/global-design-system';
import { withTranslation } from 'react-i18next';
import mobileLogo from './images/mobile.png';

import './desktop.scss';
import { RootContext, StateContext } from '@/root/root.provider';
import { redeemTickets } from '../wallet/envelope/envelope.service';
import walletBannerOpts from '../wallet/wallet.banners';
import { getRedemptionReasonByCode } from '../wallet/manage/redemption/redemptionDisabledReasons';
import Spinner from '../partials/spinner/spinner';
import { T } from '../helpers/i18n';
import { EnvelopeModel } from '../models/envelopeModel.ts';
import Banner from '../partials/banner/banner.tsx';

type Props = {
  envelope: EnvelopeModel;
  err: any;
} & T;

type State = {
  loading: boolean;
  redirection: boolean;
};

class Desktop extends Component<Props, State> {
  static contextType: React.Context<StateContext> = RootContext;

  state: State = {
    loading: true,
    redirection: false
  };

  async componentDidMount() {
    let envelope = this.props.envelope;
    let err = this.props.err;

    if (envelope?.redirectToRedemptionOnDesktop) {
      if (envelope.redemptionAvailability?.redemptionDisabled) {
        this.context.showBanner(walletBannerOpts.redemptionError(getRedemptionReasonByCode(envelope.redemptionAvailability.reason)));
      } else {
        try {
          this.setState({ loading: true });
          let error = await redeemTickets(envelope.activeToken);
          if (error) {
            this.context.showBanner(error);
          } else {
            this.setState({ redirection: true });
          }
        } catch (err) {
          this.setState({ err: err });
        }
      }
    }

    if (err?.validate?.status === 'alreadyRedeemed') {
      window.location.href = err.validate.tmOrdersUrl;
      this.setState({ redirection: true });
    }

    this.setState({ loading: false });
  }

  render() {
    const { t } = this.props;
    const { banner } = this.context.state;

    return (
      <div>
        <Spinner isShown={this.state.loading || this.state.redirection}/>
        {!this.state.loading &&
          !this.state.redirection &&
          (
            <div className="desktop">
              <Banner config={banner}/>
              <img className="mobile-logo" src={mobileLogo} alt="Mobile only"/>
              <TextStyle.Fiji className="heading-text">{t('DESKTOP.TITLE')}</TextStyle.Fiji>
              <TextStyle.Rainier className="main-text">{t('DESKTOP.TEXT')}</TextStyle.Rainier>
            </div>
          )}
      </div>
    );
  }
}

export default withTranslation('translation')(memo(Desktop));
