// @ts-nocheck

import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';

import InfoItem from './item/item';
import AdditionalInfo from './additionalInfo/additionalInfo';
import { getEventDateRange, getEventStartLocalDateTime } from '../../helpers/moment/moment.utils';
import { getSingleFee } from '../../helpers/fee/feeHelper';

import './info.scss';
import i18next from 'i18next';
import { getFormattedVenueAddress } from '../../models/eventModel';
import { EnvelopeFeeDisplayType, EnvelopeModel, resolveReferenceId } from '../../models/envelopeModel';
import { getBestDescription, TicketModel } from '../../models/ticketModel.ts';
import Charge from '../../wallet/info/item/charge.tsx';
import Charges from '../../wallet/info/item/charges.tsx';

type Props = {
  envelope: EnvelopeModel;
  active: boolean;
} & T;

const Info = ({ t, envelope, active }: Props) => {
  const envelopeFeeDisplayType = EnvelopeFeeDisplayType[envelope.envelopeFeeDisplayType];

  const event = envelope.event;
  const ticket: TicketModel = envelope.tickets.find((t) => t.id === active);
  const currency = ticket.currencyCode?.toUpperCase()
  const locale = i18next.language;
  const singleFee = getSingleFee(envelope, ticket);
  const total = ticket.price - ticket.discount + singleFee + ticket.tax;
  const subtotal = envelopeFeeDisplayType === EnvelopeFeeDisplayType.ROLLED_UP ? total : ticket.price - ticket.discount;
  const referenceId = resolveReferenceId(envelope);

  const shouldDisplaySingleFee = getShouldDisplaySingleFee(envelopeFeeDisplayType, singleFee, ticket.fees);
  const shouldDisplayCompleteBreakdown = getShouldDisplayCompleteBreakdown(envelopeFeeDisplayType, ticket.fees);
  const shouldDisplayTaxes = envelopeFeeDisplayType !== EnvelopeFeeDisplayType.ROLLED_UP && isGreaterThanZero(ticket.tax)
  const shouldDisplayTotal = total !== subtotal;
  const shouldDisplayPrice = ticket.price != null;

  function isGreaterThanZero(input: number): boolean {
    return input != null && input > 0;
  }

  function containsGreaterThanZero(input: number[]): boolean {
    return input.some(num => num != null && num > 0);
  }

  function getShouldDisplayCompleteBreakdown(envelopeFeeDisplayType: EnvelopeFeeDisplayType, fees: Fees[]): boolean {
    return envelopeFeeDisplayType === EnvelopeFeeDisplayType.COMPLETE_BREAKDOWN && fees && containsGreaterThanZero(fees.map((fee) => fee.value))
  }

  function getShouldDisplaySingleFee(envelopeFeeDisplayType: EnvelopeFeeDisplayType, singleFee: number, fees: Fees[]): boolean {
    if (!isGreaterThanZero(singleFee)) {
      return false;
    }

    if (envelopeFeeDisplayType === EnvelopeFeeDisplayType.SINGLE_LINE) {
      return true;
    }

    if (envelopeFeeDisplayType === EnvelopeFeeDisplayType.COMPLETE_BREAKDOWN) {
      return !fees || !containsGreaterThanZero(fees.map((fee) => fee.value));
    }

    return false;
  }

  const getBarcodeOrNoBarcodeMessage = (): string => {
    const { isSecureEntry, barcode } = ticket;

    if (barcode) {
      return barcode;
    }

    return isSecureEntry ? t('WALLET.TICKETS.BARCODE_AVAILABLE_LATER') : t('WALLET.TICKETS.NO_BARCODE');
  };

  const getTermsAndConditions = (): string => {
    const { termsAndConditions } = ticket;

    if (termsAndConditions) {
      return termsAndConditions;
    }
    return t('WALLET.INFO.TERMS_AND_CONDITIONS_ERROR');
  };

  return (
    <div className="wallet-info">
      {!!referenceId && (
        <InfoItem title={'WALLET.INFO.ORDER_ID'} data={referenceId}/>
      )}
      <InfoItem title={'WALLET.INFO.EVENT_ID'} data={event.systemId || event.discoveryId}/>
      {!!event.venue.address && (
        <InfoItem title={'WALLET.INFO.VENUE_ADDRESS'} data={getFormattedVenueAddress(event.venue)}/>
      )}
      {!!event.startLocalDate && (
        <InfoItem title={'WALLET.INFO.DATE_TIME'} data={getEventStartLocalDateTime(event.startLocalDate, event.startLocalTime)}/>
      )}
      {!!event.dateRange && (
        <InfoItem title={'WALLET.INFO.DATE_TIME'} data={getEventDateRange(event.dateRange)}/>
      )}
      {!event.startLocalDate && !event.dateRange && (
        <InfoItem title={'WALLET.INFO.DATE_TIME'} data={t('WALLET.INFO.TBA_SHORTEN')}/>
      )}
      <InfoItem title={'WALLET.INFO.BARCODE'} data={getBarcodeOrNoBarcodeMessage()}/>
      <InfoItem title={'WALLET.INFO.TICKET_TYPE'} data={getBestDescription(ticket) || t('WALLET.TICKETS.DESCRIPTION')}/>
      {shouldDisplayPrice &&
          <InfoItem
              title={'WALLET.INFO.CHARGES'}
              data={[
                <Charge name={t('WALLET.INFO.CHARGES_TICKET_VALUE')} value={subtotal} locale={locale} currency={currency}/>,
                shouldDisplaySingleFee && <Charge name={t('WALLET.INFO.CHARGES_TICKET_FEE')} value={singleFee} locale={locale} currency={currency}/>,
                shouldDisplayCompleteBreakdown && <Charges name={t('WALLET.INFO.CHARGES_TICKET_FEE')} charges={ticket.fees} locale={locale} currency={currency}/>,
                shouldDisplayTaxes && <Charge name={t('WALLET.INFO.CHARGES_TICKET_TAX')} value={ticket.tax} locale={locale} currency={currency}/>,
                shouldDisplayTotal && <Charge name={t('WALLET.INFO.CHARGES_TICKET_TOTAL')} value={total} locale={locale} currency={currency}/>
              ]}
          />
      }
      <AdditionalInfo title={'WALLET.INFO.ADDITIONAL_INFO'} ticket={ticket} countryCode={event.venue.countryCode}/>
      <InfoItem
        title={'WALLET.INFO.TERMS_AND_CONDITIONS'}
        data={getTermsAndConditions()}
      />
    </div>
  );
};

export default withTranslation('translation')(memo(Info));
