import { EventModel } from './eventModel';
import { TicketModel } from './ticketModel';

export type EnvelopeModel = {
  tickets: TicketModel[];
  userLanguage: string;
  event: EventModel;
  activeToken: string;
  tmOrderUrl: string;
  redemptionAvailability: RedemptionAvailabilityModel;
  shouldRedirectToRedemption: boolean;
  redirectToRedemptionOnDesktop: boolean;
  otherFees: number;
  orderId: string | null;
  locatorId: string | null;
  envelopeFeeDisplayType: EnvelopeFeeDisplayType;
  featureFlags: { [key:string]:boolean; };
}

export type RedemptionAvailabilityModel = {
  redemptionDisabled: boolean;
  reason: string;
}

export enum EnvelopeFeeDisplayType {
  ROLLED_UP,
  SINGLE_LINE,
  COMPLETE_BREAKDOWN
}

export function resolveReferenceId(envelope: EnvelopeModel): string | null {
  return envelope.locatorId || envelope.orderId;
}
