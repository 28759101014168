// @ts-nocheck

import React, { memo, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { ReactComponent as TicketsIcon } from '../../../../assets/svg/Tickets-24.svg';
import { RootContext } from '@/root/root.provider';
import { T } from '../../../../helpers/i18n';

type Props = {
  activeToken: string;
} & T;
const NoBarcode = ({ activeToken, t }: Props) => {
  const context = useContext(RootContext);

  function ticketsLinkAnchorClick(activeToken: string, event: Event) {
    event.preventDefault();
    context.addToAccount(activeToken);
  }

  return (
    <div>
      <div className={'link-tickets-icon'}>
        <TicketsIcon/>
      </div>
      <div id={'link-tickets-without-icon'}>
        <a id="ticket-link-a" className={'tickets-link'} href="/#" onClick={(event) => ticketsLinkAnchorClick(activeToken, event)}>
          {t('WALLET.TICKETS.LINK_TICKETS')}
        </a>
        &nbsp;{t('WALLET.TICKETS.VIEW_MANAGE_TICKETS')}
      </div>
    </div>
  );
};

export default withTranslation('translation')(memo(NoBarcode));
