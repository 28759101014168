// @ts-nocheck
import React, { memo, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import { RootContext } from '@/root/root.provider';
import { T } from '../../../../helpers/i18n';

import './header.scss';
import { getFieldBackgroundColor, getTextFontColor } from '../../../../helpers/ticketColors/ticketColorsHelper';
import { getBestDescription } from '../../../../models/ticketModel.ts';
import { TicketTopSection, TicketTopSectionGeneric } from '@ticketmaster/global-design-system';
import { GENERAL_ADMISSION, GENERAL_ADMISSION_REF } from '../../../../wallet/tickets/ticket/fields/field/field.constants.ts';

type Props = {
  ticket: TicketModel;
} & T;

const TicketHeader = ({ t, ticket }: Props) => {
  const rootContext = useContext(RootContext);
  const fieldsBackgroundColor = getFieldBackgroundColor(ticket);
  const textFontColor = getTextFontColor(ticket);
  const formatFieldTitle = (title: string) => {
    if (!title) {
      return '';
    }
    return t(`WALLET.TICKETS.TICKET.${title.toUpperCase()}`);
  };

  const formatFieldValue = (value: object | string) => {
    if (!value) {
      return '-';
    }
    if (Array.isArray(value)) {
      return value.join('-');
    }
    return value;
  };

  return (
    ticket.isGA ? (
        ticket.showGARef ? <TicketTopSectionGeneric
            headerTitle={getBestDescription(ticket) || t('WALLET.TICKETS.DESCRIPTION')}
            mainText={ formatFieldTitle(GENERAL_ADMISSION_REF) } // TODO BLOX-3038: wrong formatting now
            subText={ formatFieldValue([ticket.section, ticket.row, ticket.seat]) }
            type="primary"
            ticketInfoButtonData={{
              accessibleDescription: 'Ticket info', // TODO BLOX-3038: translate or skip?
              callback: () => {rootContext.setInfo(ticket.id)}
            }}
            wayfindingColors={{
              background: fieldsBackgroundColor,
              foreground: textFontColor
            }}
          /> :
          <TicketTopSectionGeneric
            headerTitle={getBestDescription(ticket) || t('WALLET.TICKETS.DESCRIPTION')}
            mainText={ formatFieldTitle(GENERAL_ADMISSION) }
            subText={ formatFieldValue(ticket.section) }
            type="primary"
            ticketInfoButtonData={{
              accessibleDescription: 'Ticket info', // TODO BLOX-3038: translate or skip?
              callback: () => {rootContext.setInfo(ticket.id)}
            }}
            wayfindingColors={{
              background: fieldsBackgroundColor,
              foreground: textFontColor
            }}
          />
      ) : <TicketTopSection
        headerTitle={getBestDescription(ticket) || t('WALLET.TICKETS.DESCRIPTION')}
        row={{
          title: formatFieldTitle('row'),
          value: formatFieldValue(ticket.row)
        }}
        seat={{
          title: formatFieldTitle('seat'),
          value: formatFieldValue(ticket.seat)
        }}
        section={{
          title: formatFieldTitle('section'),
          value: formatFieldValue(ticket.section)
        }}
        ticketInfoButtonData={{
          accessibleDescription: 'Ticket info', // TODO BLOX-3038: translate or skip?
          callback: () => {rootContext.setInfo(ticket.id)}
        }}
        type="primary"
        wayfindingColors={{
          background: fieldsBackgroundColor, // TODO BLOX-3038: Is it the right color?
          foreground: textFontColor
        }}
      />
  );
};

export default withTranslation('translation')(memo(TicketHeader));
