// @ts-nocheck

import React, { Component } from 'react';
import './ticketsRedeemed.scss';
import { Button } from "@ticketmaster/global-design-system";
import { withTranslation } from 'react-i18next';
import { ReactComponent as TicketsRedeemedIcon } from '../../assets/svg/tickets-redeemed-icon.svg';
import { RootContext, StateContext } from '@/root/root.provider';
import { T } from '../../helpers/i18n';

type Props = {} & T;

class TicketsRedeemed extends Component<Props> {
  static contextType: React.Context<StateContext> = RootContext;

  render() {
    const { t } = this.props;
    return (
      <div className={`ticketsRedeemed`}>
        <TicketsRedeemedIcon className={'tickets-redeemed-icon'}/>
        <div className={'ticketsRedeemedText'}>
          <div className={'ticketsRedeemedMessage'}>{t('WALLET.TICKETS_REDEEMED.MESSAGE_TICKETS_REDEEMED')}</div>
          <div
            className={'ticketsRedeemedLoginMessage'}
            dangerouslySetInnerHTML={{ __html: t('WALLET.TICKETS_REDEEMED.MESSAGE_LOGIN') }}
          />
        </div>
        <div>
          <Button fillVariant="outline" onClick={() => window.location.assign(this.context.state.tmOrdersUrl)}>
            {t('WALLET.TICKETS_REDEEMED.LOGIN')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(TicketsRedeemed);
