import { TextStyle } from '@ticketmaster/global-design-system';
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { VOIDED_TEXT_COLOR_HEX } from '../../../../wallet/wallet.constants';
import { T } from '../../../../helpers/i18n';

const VoidedBarcode = ({ t }: T) => {
  return (
    <div id="voided-barcode">
      <TextStyle.Rainier className="text-block" style={{ color: VOIDED_TEXT_COLOR_HEX }}>
        {t('WALLET.TICKETS.TICKET.INVALID_TICKET')}
        <br/>
        <br/>
        {t('WALLET.TICKETS.TICKET.INVALID_TICKET_FAN_SUPPORT')}
      </TextStyle.Rainier>
    </div>
  );
};

export default withTranslation('translation')(memo(VoidedBarcode));
