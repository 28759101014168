// @ts-nocheck
import React, { Component } from 'react';

import TicketEvent from './event/event';
import TicketAdditionalInfo from './additionalInfo/ticketAdditionalInfo';
import TicketBarcode from './barcode/barcode';

import './ticket.scss';
import { getTicketBackgroundColor } from '../../../helpers/ticketColors/ticketColorsHelper';
import { EventModel } from '../../../models/eventModel';
import { TicketCardv2 } from '@ticketmaster/global-design-system';
import { TicketModel } from '../../../models/ticketModel.ts';
import TicketHeader from '../../../wallet/tickets/ticket/header/header.tsx';

type Props = {
  activeToken: string;
  ticket: TicketModel;
  index: number;
  event: EventModel;
};

type State = any;

class Ticket extends Component<Props, State> {
  render() {
    const { ticket, event, activeToken, index } = this.props;
    const ticketBackgroundColor = getTicketBackgroundColor(ticket);

    return (
      <TicketCardv2 status="primary" style={{ backgroundColor:  ticketBackgroundColor }}>
        <TicketHeader ticket={ticket} />
        <TicketEvent event={event} ticket={ticket} />
        <TicketBarcode activeToken={activeToken} ticket={ticket} index={index} event={event} />
        <TicketAdditionalInfo ticket={ticket} />
      </TicketCardv2>
    );
  }
}

export default Ticket;
