import React from 'react';
import { getImage } from './image.service';
import RotatingLine from './rotatingLine/rotatingLine';
import { TextStyle } from '@ticketmaster/global-design-system';
import { Trans, withTranslation } from 'react-i18next';

import './image.scss';
import { T } from '../../../../../helpers/i18n';

type Props = {
  barcodeType: string;
  barcodeValue: string;
  isSecureEntry: boolean;
} & T;

const BarcodeImage = ({ barcodeValue, isSecureEntry, barcodeType, t }: Props) => {
  const barcodeImage = getImage({ barcodeValue, isSecureEntry, barcodeType });
  const isBarcode = barcodeType.toLowerCase() === 'qrcode' || barcodeType.toLowerCase() === 'barcode';

  const renderNoBarcodeMessage = () => {
    if (barcodeImage) {
      return;
    }

    return (
      <TextStyle.Etna>
        <Trans>{'WALLET.TICKETS.NO_BARCODE'}</Trans>
      </TextStyle.Etna>
    );
  };

  return (
    <div>
      <div className={`image-container${!isSecureEntry && isBarcode ? ' qr-code' : ''}`}>
        {isSecureEntry && !!barcodeImage && <RotatingLine/>}
        {renderNoBarcodeMessage()}
        {!!barcodeImage && <img className={`barcode-image`} src={`${barcodeImage}`} alt={barcodeValue}/>}
      </div>
      {isSecureEntry && !!barcodeImage && <TextStyle.Snowdon size={'uno'}>{t('WALLET.TICKETS.SCREENSHOTS_ARE_NOT_VALID')}</TextStyle.Snowdon>}
    </div>
  );
};

export default withTranslation('translation')(BarcodeImage);
