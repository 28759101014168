// @ts-nocheck
import React from 'react';
import { ChevronIcon } from '@ticketmaster/global-design-system';
import './arrow.scss';

const formatClassName = (direction, disabled) => `slick-arrow ${direction}${disabled ? ' disabled' : ''}`;

const SlickArrow = ({ onClick, direction = 'right', disabled = false }) => (
  <div className={formatClassName(direction, disabled)}>
    <ChevronIcon
      size={15}
      fillColor={disabled ? '#bababa' : '#514e4e'}
      rotate={direction === 'right' ? -90 : 90}
      onClick={!disabled ? onClick : undefined}
    />
  </div>
);

export default SlickArrow;
