import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { TextStyle } from '@ticketmaster/global-design-system';

import './spinner.scss';
import { T } from '../../helpers/i18n';

type Props = {
  isShown: boolean;
} & T;

const Spinner = ({ t, isShown }: Props) => {
  return (
    <Fragment>
      {isShown && (
        <div className="spinner">
          <TextStyle.Etna>{t('BASE.LOADING')}</TextStyle.Etna>
        </div>
      )}
    </Fragment>
  );
};

export default withTranslation('translation')(Spinner);
