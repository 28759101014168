import React, { memo } from 'react';
import { getAdditionalInfos } from '../../../../helpers/additionalInfo/additionalInfoHelper';

import './ticketAdditionalInfo.scss';

type Props = {
  ticket: TicketModel;
};

const TicketAdditionalInfo = ({ ticket }: Props) => {
  if (!ticket?.additionalInfos) return null;
  const additionalInfo = getAdditionalInfos(ticket, true)
  if (additionalInfo.length) {
    return (
      <div className="ticket-additional-info">
        {additionalInfo}
      </div>
    );
  }
  return null;
};

export default (memo(TicketAdditionalInfo));
