// @ts-nocheck

import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { HEADER_TITLE } from './header.constants';
import { RootContext } from '@/root/root.provider';

import type { T } from '../helpers/i18n';
import { ArrowIcon, Header, IconButton, TicketmasterTIcon } from '@ticketmaster/global-design-system';

export const AppHeader = ({ t }: T) => {
  const context = useContext(RootContext);

  const logo = context.state.info ? (
    <IconButton onClick={() => context.setInfo(null)}>
      <ArrowIcon />
    </IconButton>
  ) : (
    <IconButton>
      <TicketmasterTIcon />
    </IconButton>
  );

  const headerTitle = context.state.info ? t('HEADER.INFO') : t(HEADER_TITLE);

  return (
    <Header logo={logo}>
      <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
        {headerTitle}
      </div>
    </Header>
  );
};

export default withTranslation('translation')(AppHeader);
