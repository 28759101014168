import { withTranslation } from 'react-i18next';
import { TextStyle } from '@ticketmaster/global-design-system';

import { T } from '../../../helpers/i18n';
import { formatMoney } from '../../../helpers/moneyFormatter/moneyFormatter';

import './item.scss';
import React from 'react';

type Props = {
  name: string;
  charges: Charge[];
  locale: string;
  currency: string;
} & T;

type Charge = {
  name: string;
  value: number;
}

const Charges = ({ name, charges = [], locale, currency }: Props) => {
  return (
    <div id={`charges-${name}`}>
      <TextStyle.Etna>{name + ':'}</TextStyle.Etna>
      <br/>
      {charges.map((charge: Charge, key) => (
        <>
          <TextStyle.Etna key={key} style={{ wordBreak: 'break-word' }}>
          &nbsp;&nbsp;{'- ' + charge.name + ' : ' + formatMoney(locale, currency, charge.value)}
          </TextStyle.Etna>
          <br/>
        </>
      ))}
    </div>
  );
};

export default withTranslation('translation')(Charges);
