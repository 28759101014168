// @ts-nocheck

import React, { Component, Fragment } from 'react';

import './root.scss';

import Header from '../header/header.tsx';
import HeaderV2 from '../v2/header/header.tsx';
import Wallet from '../wallet/wallet.tsx';
import WalletV2 from '../v2/wallet/wallet.tsx';
import Desktop from '../desktop/desktop.tsx';
import DesktopV2 from '../v2/desktop/desktop.tsx';
import { updateTitle } from '../helpers/other/strings';
import { getEnvelope } from '../wallet/envelope/envelope.service';
import RootProvider, { RootContext, StateContext } from './root.provider.tsx';
import { isMobile } from '@/helpers/device';
import { EnvelopeModel } from '@/models/envelopeModel.ts';
import { T } from '@/helpers/i18n';
import Spinner from '@/partials/spinner/spinner.tsx';

type State = {
  loading: boolean;
  envelope: EnvelopeModel;
};

class Root extends Component<T, State> {
  static contextType: React.Context<StateContext> = RootContext;
  token: string = window.location.pathname.substring(1);

  state: State = {
    loading: true,
    envelope: null,
    err: null
  };

  async componentDidMount() {
    if (this.token) {
      try {
        const { data: envelope } = await getEnvelope(this.token);
        this.setState({ envelope: envelope });
      } catch (err: any) {
        this.setState({ err: err })
      }
    }
    this.setState({ loading: false });
  }

  render() {
    updateTitle();

    const { loading, envelope, err } = this.state;
    const isMobileDevice: boolean = isMobile.any();

    if (loading) {
      return isMobileDevice ? <></> : <Spinner isShown={true} />;
    }

    const useNewUI = envelope?.featureFlags?.['ui-v2'];

    return (
      <RootProvider>
        {!isMobileDevice ? (
          useNewUI ? <DesktopV2 envelope={envelope} err={err}/> : <Desktop envelope={envelope} err={err}/>
        ) : (
          <Fragment>
            {useNewUI ? <HeaderV2 /> : <Header />}
            {useNewUI ? <WalletV2 envelope={envelope} err={err}/> : <Wallet envelope={envelope} err={err}/>}
          </Fragment>
        )}
      </RootProvider>
    );
  }
}

export default Root;
