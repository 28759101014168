import { withTranslation } from 'react-i18next';
import { TextStyle } from '@ticketmaster/global-design-system';

import { T } from '../../../helpers/i18n';
import { formatMoney } from '../../../helpers/moneyFormatter/moneyFormatter';

import './item.scss';
import React from 'react';

type Props = {
  name: string;
  value: number;
  locale: string;
  currency: string;
} & T;

const Charge = ({ name, value, locale, currency }: Props) => {
  return (
    <div id={`charge-${name}`}>
      <TextStyle.Etna>{`${name}: ${formatMoney(locale, currency, value)}`}</TextStyle.Etna>
    </div>
  );
};

export default withTranslation('translation')(Charge);
