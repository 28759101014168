const walletBannerOpts = {
  noToken: () => ({
    heading: 'WALLET.UNAVAILABLE_SHORT',
    variant: 'warning',
    content: 'WALLET.NO_TOKEN',
    onRequestClose: null,
  }),
  noTickets: () => ({
    heading: 'WALLET.UNAVAILABLE_SHORT',
    variant: 'error',
    content: 'WALLET.UNAVAILABLE',
    onRequestClose: null,
  }),
  defaultError: () => ({
    heading: 'WALLET.UNAVAILABLE_SHORT',
    variant: 'error',
    content: 'WALLET.NO_AVAILABLE_TICKETS',
    onRequestClose: null,
    onButtonClick: undefined,
    isExpanded: true,
    expandedText: undefined,
  }),
  notReady: () => ({
    ...walletBannerOpts.defaultError(),
    variant: 'info',
    content: 'WALLET.ENVELOPE_IS_NOT_READY',
  }),
  redeemError: () => ({
    heading: 'BASE.ERROR',
    variant: 'error',
    content: 'WALLET.MANAGE.ADD_TO_ACCOUNT.CANNOT_REDEEM',
    onRequestClose: null,
    isExpanded: true,
  }),
  redemptionError: (errorMessage : String) => ({
    heading: 'WALLET.UNAVAILABLE_SHORT',
    variant: 'error',
    content: errorMessage,
    onRequestClose: null,
  }),
};

export default walletBannerOpts;
