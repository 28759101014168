// @ts-nocheck
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Banner } from '@ticketmaster/aurora';

import './alertBanner.scss';
import { T } from '@/helpers/i18n';
import { getRedemptionReasonByCode } from '@/wallet/manage/redemption/redemptionDisabledReasons';

type Props = {
  redemptionAvailability: RedemptionAvailabilityModel;
  onClose: Function;
} & T;

const AlertBanner = (props: Props) => {
  const { t, redemptionAvailability, onClose } = props;

  if (!redemptionAvailability?.redemptionDisabled) {
    return null;
  }
  return (
    <div className={'alert-banner'}>
      <Banner
        heading={t(getRedemptionReasonByCode(redemptionAvailability.reason))}
        isOpen
        onRequestClose={onClose}
        variant="info"
      />
    </div>
  );
};

export default withTranslation('translation')(AlertBanner);
