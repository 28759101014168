// @ts-nocheck
import { CrossIcon } from '@ticketmaster/global-design-system';
import React from 'react';
import { VOIDED_TEXT_COLOR_HEX } from '../../../../../wallet/wallet.constants';

const VoidedDateAndLocation = () => (
  <div id={"voided-date-and-location"}>
    <CrossIcon fillColor={ VOIDED_TEXT_COLOR_HEX } style={{width: "100%", height: 30, textAlign: "center"}}/>
  </div>
);

export default VoidedDateAndLocation;
