import { TextStyle } from '@ticketmaster/global-design-system';
import { getEventDateRange, getEventStartLocalDateTime } from '../../../../../helpers/moment/moment.utils';
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { T } from '../../../../../helpers/i18n';
import { EventModel } from '../../../../../models/eventModel';

type Props = {
  event: EventModel
} & T;

const DateAndLocation = ({ event, t }: Props) => {

  return (
    <div>
      <TextStyle.Rainier className="text-block-date-location" size="kilo">
        {!!event.dateRange && getEventDateRange(event.dateRange)}
        {!event.dateRange && !!event.startLocalDate && getEventStartLocalDateTime(event.startLocalDate, event.startLocalTime)}
        {!event.dateRange && !event.startLocalDate && t('WALLET.INFO.TBA')}
      </TextStyle.Rainier>
      <br/>
      <TextStyle.Rainier className="text-block-date-location" size="kilo">
        {event.venue.title.replace(/\sat\s/, ' @ ')}
      </TextStyle.Rainier>
    </div>
  );
};

export default withTranslation('translation')(memo(DateAndLocation));
