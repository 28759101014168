// @ts-nocheck
import React from 'react';
import { withTranslation } from 'react-i18next';

import './alertBanner.scss';
import { T } from '../../../../helpers/i18n';
import { getRedemptionReasonByCode } from '../../../../wallet/manage/redemption/redemptionDisabledReasons';
import Banner from '../../../../partials/banner/banner.tsx';

type Props = {
  redemptionAvailability: RedemptionAvailabilityModel;
  onClose: Function;
} & T;

const AlertBanner = (props: Props) => {
  const { t, redemptionAvailability, onClose } = props;

  if (!redemptionAvailability?.redemptionDisabled) {
    return null;
  }
  return (
    <div className={'alert-banner'}>
      <Banner config={{heading: t(getRedemptionReasonByCode(redemptionAvailability.reason)), variant: 'info', onRequestClose: onClose }}/>
    </div>
  );
};

export default withTranslation('translation')(AlertBanner);
