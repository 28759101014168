// @ts-nocheck
import { withTranslation } from 'react-i18next';
import React, { memo } from 'react';
import { getBarcodeBackgroundColor, getBarcodeBorderColor } from '../../../../helpers/ticketColors/ticketColorsHelper';
import NoBarcode from './noBarcode';
import VoidedBarcode from './voidedBarcode';

type Props = {
  activeToken: string;
  ticket: TicketModel;
}

const NoOrVoidedBarcode = ({ activeToken, ticket }: Props) => {
  const barcodeBackgroundColor = getBarcodeBackgroundColor(ticket);
  const barcodeBorderColor = getBarcodeBorderColor(ticket);

  function displayNoOrVoidedBarcode() {
    if (ticket.voidedAt != null) {
      return <VoidedBarcode/>;
    }
    return <NoBarcode activeToken={activeToken}/>;
  }

  return (
    <div className={'no-barcode-box'} style={{ backgroundColor: barcodeBackgroundColor, borderColor: barcodeBorderColor }}>
      {displayNoOrVoidedBarcode()}
    </div>
  );
};

export default withTranslation('translation')(memo(NoOrVoidedBarcode));
